import React from 'react';
import { FaDiscord, FaPlay } from 'react-icons/fa6';
import pepe2 from '../images/pepe2.jpg';

const Tools = () => {
  return (
    <div className="tools-container" style={{
      padding: "40px 20px",
      maxWidth: "1200px",
      margin: "0 auto",
      display: "flex",
      flexWrap: "wrap",
      gap: "40px",
      justifyContent: "center"
    }}>
      <div className="tool-card">
        <h2>Heremes</h2>
        <img 
          src={pepe2}
          alt="Tool 1"
          className="tool-image"
        />
        <h2>Mass Sniper</h2>
        <p>Buy tokens and split across many wallets. Multiply holders in an instant.</p>

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
          <a 
            href="https://youtu.be/PKWbbbhznq8?si=WUlOhPSjGnEAogEy" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }}  />
            Watch Demo
          </a>
          <a 
            href="https://discord.gg/8W8TdfKXMT" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaDiscord style={{ marginRight: '8px' }} size={20}  />
            Get Access
          </a>
        </div>
      </div>

      {/* <div className="tool-card">
        <img 
          src={pepe2}
          alt="Tool 2"
          className="tool-image"
        />
        <h2>Tool Title 2</h2>
        <p>Description of your second tool. Add details about what this tool does and its benefits.</p>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
          <a 
            href="your-demo-video-link-here" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }} />
            Watch Demo
          </a>
          <a 
            href="https://discord.gg/8W8TdfKXMT" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaDiscord style={{ marginRight: '8px' }} />
            Get Access
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Tools; 
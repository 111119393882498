import logo from './logo.svg';
import './App.css';
import { Typewriter } from 'react-simple-typewriter';
import Logo from './images/logo.png';
import { useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import Tools from './pages/Tools';
import { FaXTwitter, FaDiscord, FaTelegram, FaYoutube, FaGithub, FaTools, FaWrench } from 'react-icons/fa6';
import { MdBuildCircle } from 'react-icons/md';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div
      className="App"
      style={{
        backgroundColor: "black", // Set the body background to black
        minHeight: "100vh", // Ensure the background covers the full viewport height
        color: "rgb(0, 255, 38)", // Set text color to the specific green
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center", // Center all content horizontally
        width: "100%",
        overflowX: "hidden", // Prevent horizontal scroll
      }}
    >
      {/* Navigation Bar */}
      <nav
        className="navbar"
        style={{
          width: "100%",
          padding: "30px 60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "black",
          color: "rgb(0, 255, 38)",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        {/* Empty div for spacing */}
        <div style={{ width: "40px" }}></div>

        {/* Center container for logo and title */}
        <div style={{ 
          display: "flex", 
          alignItems: "center",
          gap: "20px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)"
        }}>
          <a href="https://whalesnipr.com">
            <img width={70} height={70} src={Logo} alt="Whalesnipr Logo"/>
          </a>
          
          <div className="navbar-title">
            <a href="https://whalesnipr.com">
              <Typewriter words={["Whalesnipr"]} cursor={false} />
            </a>
          </div>
        </div>

        {/* Desktop Navigation */}
        <div className="desktop-nav" style={{ 
          display: "flex", 
          gap: "30px", 
          marginLeft: "auto"
        }}>
          <Link to="/tools" style={{ textDecoration: 'none' }}>
            <button className='text-button'>Tools</button>
          </Link>
          <a href="https://github.com/whalesnipr-foundation" target="_blank" rel="noreferrer">
            <button className='text-button'>Code</button>
          </a>
        </div>

        {/* Hamburger Menu */}
        <div className="hamburger-menu">
          <button 
            className={`hamburger-button ${isMenuOpen ? 'open' : ''}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
      <Link to="/tools" style={{ textDecoration: 'none' }} onClick={() => setIsMenuOpen(false)}>
          <button className='text-button'>Tools</button>
        </Link>
        <a 
          href="https://github.com/whalesnipr-foundation" 
          target="_blank" 
          rel="noreferrer" 
          onClick={() => setIsMenuOpen(false)}
        >
          <button className='text-button'>Code</button>
        </a>
        <div className="mobile-social-links">
          <a href="https://x.com/whalesnipr" target="_blank" rel="noreferrer">
            <FaXTwitter size={24} />
          </a>
          <a href="https://discord.gg/8W8TdfKXMT" target="_blank" rel="noreferrer">
            <FaDiscord size={24} />
          </a>
          <a href="https://t.me/+MerO0iO9w7pmOGI0" target="_blank" rel="noreferrer">
            <FaTelegram size={24} />
          </a>
          <a href="https://www.youtube.com/@Whalesnipr" target="_blank" rel="noreferrer">
            <FaYoutube size={24} />
          </a>
          <a href="https://github.com/Whalesnipr-Foundation" target="_blank" rel="noreferrer">
            <FaGithub size={24} />
          </a>
        </div>
      </div>

      {/* Routes */}
      <Routes>
        <Route path="/" element={
          <div className="responsive-text" style={{ display: "flex", flexDirection: "column", gap: "10px", paddingRight: "20px", paddingLeft: "20px", lineHeight: '1.5' }}>
            <Typewriter
              words={["Trade with an edge."]}
              cursor={false}
              delaySpeed={5000}
            />
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Link to="/tools" style={{ textDecoration: 'none' }}>
                <FaWrench 
                  size={24} 
                  color="rgb(0, 255, 38)" 
                  style={{ 
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease, color 0.2s ease'
                  }}
                  onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                  onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                />
              </Link>
            </div>
          </div>
        } />
        <Route path="/tools" element={<Tools />} />
      </Routes>

      <footer
        className="footer"
        style={{
          width: "100%",
          height: "100px",
          padding: "10px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "black",
          color: "rgb(0, 255, 38)",
        }}
      >
        <div className="footer-links">
          <a href="https://x.com/whalesnipr" target="_blank" rel="noreferrer">
            <FaXTwitter size={24} />
          </a>
          <a href="https://discord.gg/8W8TdfKXMT" target="_blank" rel="noreferrer">
            <FaDiscord size={24} />
          </a>
          <a href="https://t.me/+MerO0iO9w7pmOGI0" target="_blank" rel="noreferrer">
            <FaTelegram size={24} />
          </a>
          <a href="https://www.youtube.com/@Whalesnipr" target="_blank" rel="noreferrer">
            <FaYoutube size={24} />
          </a>
          <a href="https://github.com/Whalesnipr-Foundation" target="_blank" rel="noreferrer">
            <FaGithub size={24} />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;